var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('update-ui',{attrs:{"loading":_vm.loading,"title":`Édition d'un étalon (${_vm.item.serialNumber})`},on:{"close":function($event){return _vm.$emit('close')},"update":_vm.update},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"4"}},[_c('h2',{staticClass:"mt-3",staticStyle:{"text-align":"center"}},[_vm._v("Cale à Gradins")])])],1),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"9"}},[_c('v-divider',{staticClass:"mb-5"})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Numéro de série","rules":[
            (v) => !!v || 'Ce champ est requis pour modifier l\'étalon',
            (v) =>
              (!!v && !_vm.isAllowed(v)) ||
              'Ce numéro de série est déjà existant',
          ]},model:{value:(_vm.serialNumber),callback:function ($$v) {_vm.serialNumber=$$v},expression:"serialNumber"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Numéro de PV","rules":[
            (v) => !!v || 'Ce champ est requis pour modifier l\'étalon',
          ]},model:{value:(_vm.pvNumber),callback:function ($$v) {_vm.pvNumber=$$v},expression:"pvNumber"}})],1)],1),_c('v-row',[_c('v-col',[_c('material-shade-autocomplete',{attrs:{"shade-id-to-include":_vm.item.materialShadeId},model:{value:(_vm.materialShadeId),callback:function ($$v) {_vm.materialShadeId=$$v},expression:"materialShadeId"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Célérité","type":"number","suffix":"m/s","rules":[
            (v) => !!v || v === 0 || 'Ce champ est requis',
            (v) => v > 0 || 'La valeur doit être positive',
            (v) => (!!v && _vm.isInteger(v)) || 'Saisir un nombre entier',
          ]},model:{value:(_vm.celerity),callback:function ($$v) {_vm.celerity=_vm._n($$v)},expression:"celerity"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Dimension minimum","type":"number","suffix":"mm","rules":[
            (v) => !!v || v === 0 || 'Ce champ est requis',
            (v) => v >= 0 || 'La valeur doit être positive',
            _vm.minDimension == null ||
              _vm.maxDimension == null ||
              _vm.minDimension < _vm.maxDimension ||
              'Le minimum doit être inférieur au maximum',
          ]},model:{value:(_vm.minDimension),callback:function ($$v) {_vm.minDimension=_vm._n($$v)},expression:"minDimension"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Dimension maximum","type":"number","suffix":"mm","rules":[
            (v) => !!v || v === 0 || 'Ce champ est requis',
            (v) => v >= 0 || 'La valeur doit être positive',
            _vm.minDimension == null ||
              _vm.maxDimension == null ||
              _vm.minDimension < _vm.maxDimension ||
              'Le maximum doit être supérieur au minimum',
          ]},model:{value:(_vm.maxDimension),callback:function ($$v) {_vm.maxDimension=_vm._n($$v)},expression:"maxDimension"}})],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }