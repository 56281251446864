
import { Component, Vue, Inject, Prop } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";
import { isInteger } from "@/tools";

import UpdateUi from "@/components/Core/Update/UpdateUI.vue";
import MaterialShadeAutocomplete from "@/components/General/Autocompletes/Equipments/MaterialShadeAutocomplete.vue";

import {
  CalibrationBlockCategory,
  CalibrationBlockItem,
  StepCalibrationBlockItem,
} from "@/domain";

import { IStepCalibrationBlockService } from "@/services";

@Component({
  components: {
    UpdateUi,
    MaterialShadeAutocomplete,
  },
  data: () => {
    return {
      CalibrationBlockCategory,
      isInteger
    };
  },
})
export default class StepBlockUpdate extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  item!: CalibrationBlockItem;

  @Prop({ required: true })
  forbiddenSerialNumbers!: string[];

  get stepCalibrationBlockService() {
    return this.container.resolve<IStepCalibrationBlockService>(
      S.STEP_CALIBRATION_BLOCK_SERVICE
    );
  }

  loading: boolean = false;
  serialNumber: string | null = null;
  minDimension: number | null = null;
  maxDimension: number | null = null;
  materialShadeId: string | null = null;
  celerity: number | null = null;
  pvNumber: string | null = null;

  mounted() {
    this.serialNumber = this.item.serialNumber;
    this.minDimension = this.item.minDimension;
    this.maxDimension = this.item.maxDimension;
    this.celerity = this.item.celerity;
    this.materialShadeId = this.item.materialShade.id;
    this.pvNumber = this.item.pvNumber;
  }

  async update() {
    try {
      this.loading = true;
      const block = await this.stepCalibrationBlockService.update(
        this.item.id,
        {
          serialNumber: this.serialNumber!,
          minDimension: this.minDimension!,
          maxDimension: this.maxDimension!,
          materialShadeId: this.materialShadeId!,
          celerity: this.celerity!,
          pvNumber: this.pvNumber!,
          disabled: this.item.disabled,
        }
      );
      this.$emit("update", StepCalibrationBlockItem.fromDetails(block));
    } finally {
      this.loading = false;
    }
  }

  isAllowed(serialNumber: string) {
    return (
      _.findIndex(
        this.forbiddenSerialNumbers,
        (v) => v.toUpperCase() == serialNumber.toUpperCase()
      ) != -1 &&
      this.item.serialNumber.toUpperCase() != serialNumber.toUpperCase()
    );
  }
}
